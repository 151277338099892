import React from "react"
import { Link } from "@reach/router"
import * as styles from "./status.module.css"

const Status = (props) => {
  const { user } = props;
  let details
  if (!user) {
    details = (
      <p className={styles[`status__text`]}>
        To get the full app experience, you’ll need to
        {` `}
        <Link className="link" to="/app/login">log in</Link>.
      </p>
    )
  } else {
    const { displayName, email } = user;
    const { auth } = props;
    details = (
      <p className={styles[`status__text`]}>
        Logged in as {displayName} ({email}
        )!
        {` `}
        <a className="link"
          href="/"
          onClick={event => {
            event.preventDefault()
            auth().signOut()
          }}
        >
          log out
        </a>
      </p>
    )
  }

  return <div className={styles.status + " shadow-xl"}>{details}</div>
}

export default Status