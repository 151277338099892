import { Link } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import Status from "../components/Status"
import WrapperView from "../components/WrapperView"

const Pricing = (props) => {
	const { user } = props;
	return (
		<Layout {...props}>
		<Status auth={props.auth}  user={props.user}  />
		<WrapperView title="">
		<section className="py-20 bg-gray-100 text-gray-800">
			<div className="container px-4 mx-auto">
				<div className="max-w-2xl mx-auto mb-16 text-center">
					<span className="font-bold tracking-wider uppercase text-primary">Pricing</span>
					<h2 className="text-4xl font-bold lg:text-5xl">Choose your best plan</h2>
				</div>
				<div className="flex flex-wrap items-stretch -mx-4">
					<div className="flex w-full mb-8 sm:px-4 md:w-1/2 lg:w-1/3 lg:mb-0 justify-center">
						<div className="flex flex-col p-6 space-y-6 rounded shadow sm:p-8 bg-gray-50">
							<div className="space-y-2">
								<h4 className="text-2xl font-bold">Free</h4>
								<p className="text-sm tracking-wide">what? </p>
								<span className="text-6xl font-bold">Free</span>
							</div>
							<p className="mt-3 leading-relaxed text-gray-600">Get you started.</p>
							<ul className="flex-1 mb-6 text-gray-600">
								<li className="flex mb-2 space-x-2">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="flex-shrink-0 w-6 h-6 text-primary">
										<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path>
									</svg>
									<span>SSL certificate for localhost and local network</span>
								</li>
								<li className="flex mb-2 space-x-2">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="flex-shrink-0 w-6 h-6 text-primary">
										<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path>
									</svg>
									<div className="indicator">
										<span className="indicator-item badge badge-primary -mr-12">while in BETA</span> 
										<span>Change of redirect IP</span>
									</div>
								</li>
								<li className="flex mb-2 space-x-2">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="flex-shrink-0 w-6 h-6 text-primary">
										<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path>
									</svg>
									<span>1 random-generated <u>redirectto.me</u> domain</span>
								</li>
								<li className="flex mb-2 space-x-2">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="flex-shrink-0 w-6 h-6 text-primary">
										<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path>
									</svg>
									<span>1 month certificate validity</span>
								</li>
								<li className="flex mb-2 space-x-2">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="flex-shrink-0 w-6 h-6 text-primary">
										<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path>
									</svg>
									<span>New domain after expiration</span>
								</li>
								<li className="flex mb-2 space-x-2">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="flex-shrink-0 w-6 h-6 text-primary">
										<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path>
									</svg>
									<span>Manual certificate renewal (after login)</span>
								</li>
							</ul>
							<a rel="noopener noreferrer" href="/app/domains" className="rounded btn btn-primary text-gray-50">Get Started</a>
						</div>
					</div>
					<div className="flex w-full mb-8 sm:px-4 md:w-1/2 lg:w-1/3 lg:mb-0 justify-center">
						<div className="flex flex-col p-6 space-y-6 rounded shadow sm:p-8 bg-primary">
							<div className="space-y-2">
								<div className="indicator">
									<h4 className="text-2xl font-bold">Developer</h4>
									<span className="indicator-item badge badge-warning -mr-12 font-bold">PROMO: 33% OFF</span> 
								</div>
								<p className="text-sm tracking-wide">only </p>
								<span className="text-6xl font-bold">$1.33
								<span className="text-sm tracking-wide">/month</span>
								</span>
							</div>
							<p className="leading-relaxed">The real deal. For your time, for your convenience.</p>
							<ul className="flex-1 space-y-2">
								<li className="flex space-x-2">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="flex-shrink-0 w-6 h-6">
										<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path>
									</svg>
									<span>Everything in Free</span>
								</li>
								<li className="flex space-x-2">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="flex-shrink-0 w-6 h-6">
										<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path>
									</svg>
									<span>Change of redirect IP</span>
								</li>
								<li className="flex space-x-2">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="flex-shrink-0 w-6 h-6">
										<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path>
									</svg>
									<span>Keep your domain names<br/>(no change after expiration)</span>
								</li>
								<li className="flex space-x-2">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="flex-shrink-0 w-6 h-6">
										<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path>
									</svg>
									<span>1-3 month certificate validity</span>
								</li>
								<li className="flex space-x-2">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="flex-shrink-0 w-6 h-6">
										<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path>
									</svg>
									<div className="indicator">
										<span className="indicator-item badge badge-warning -mr-12">coming soon</span> 
										<span>API access</span>
									</div>
								</li>
							</ul>
							{user ? 
								<div className="dropdown">
									<label tabIndex={0} className="rounded btn btn-neutral text-primary w-full">Subscribe</label>
									<ul tabIndex={0} className="dropdown-content menu p-2 space-y-2 shadow bg-primary rounded-box w-full">
									<li>
										<a rel="noopener noreferrer" href={user ? "https://buy.stripe.com/5kA4hHfkB9eqaGIbII?prefilled_promo_code=DEVPROMO33&client_reference_id="+user.uid+"&prefilled_email="+user.email : "/app/domains"} className="rounded btn btn-neutral btn-outline">
										$1.33 / month
										</a>
									</li>
									<li>
										<a rel="noopener noreferrer" href={user ? "https://buy.stripe.com/14k15vc8p4Yag12cMN?prefilled_promo_code=DEVPROMO33&client_reference_id="+user.uid+"&prefilled_email="+user.email : "/app/domains"} className="rounded btn btn-neutral btn-outline">
											<div className="indicator">
												<span className="indicator-item badge badge-warning -mr-12" style={{color: '#3d4451'}}>44% off</span> 
												<span>$13.4 / year</span>
											</div>
										</a>
									</li>
									</ul>
								</div>
								:
								<Link to="/app/domains"><button className="rounded btn btn-neutral text-primary w-full">Get Started</button></Link>
							}
						</div>
					</div>
					<div className="flex w-full mb-8 sm:px-4 md:w-1/2 lg:w-1/3 lg:mb-0 justify-center">
						<div className="flex flex-col p-6 space-y-6 rounded shadow sm:p-8 bg-gray-50">
							<div className="space-y-2">
								<h4 className="text-2xl font-bold">Team</h4>
								<p className="text-sm tracking-wide">from </p>
								<span className="text-6xl font-bold">$10
									<span className="text-sm tracking-wide">/month</span>
								</span>
							</div>
							<p className="leading-relaxed text-gray-600">Getting serious, getting more advanced.</p>
							<ul className="space-y-2 text-gray-600">
								<li className="flex items-start space-x-2">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="flex-shrink-0 w-6 h-6 text-primary">
										<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path>
									</svg>
									<span>Everything in Developer</span>
								</li>
								<li className="flex items-start space-x-2">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="flex-shrink-0 w-6 h-6 text-primary">
										<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path>
									</svg>
									<span>5 random-generated <u>redirectto.me</u> domain</span>
								</li>
								<li className="flex items-start space-x-2">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="flex-shrink-0 w-6 h-6 text-primary">
										<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path>
									</svg>
									<span>Customizable domain names <br/>(e.g. <u>yourname.redirectto.me</u>)</span>
								</li>
								<li className="flex items-start space-x-2">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="flex-shrink-0 w-6 h-6 text-primary">
										<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path>
									</svg>
									<div className="indicator">
										<span className="indicator-item badge badge-warning -mr-12">coming soon</span> 
										<span>Automatic certificate renewal</span>
									</div>
								</li>
							</ul>
							<a rel="noopener noreferrer" href={user ? "mailto:subscription@redirectto.me" : "/app/domains"} className="w-full rounded btn btn-primary text-gray-50">{user ? "Contact us" : "Get Started"}</a>
						</div>
					</div>
				</div>
			</div>
		</section>
		</WrapperView>
		</Layout>
	)
}

export default Pricing