import React from "react"
//import { Helmet } from "react-helmet"

import Header from "../Header"

// Global styles and component-specific styles.
//import "./global.css"
//import * as styles from "./main.module.css"
//TODO: ad header title
const Layout = ({ children, ...rest }) => (
  <div className="text-base-content">
    <Header {...rest}/>
    <main className="min-h-screen">{children}</main>
    <footer className="footer footer-center p-4 bg-neutral text-neutral-content">
      <div>
        <p>Questions? support@redirectto.me</p>
        <p>Copyright © 2023 - All rights reserved by Tweaked.Tech</p>
      </div>
    </footer>
  </div>
)

export default Layout

