import React from "react"
import { Link } from "gatsby"

const Header = (props) => (
  <>
  {/*console.log("yaa", props)*/}
  <div className="navbar bg-primary text-primary-content flex flex-wrap">
    <div className="flex-1">
      <div className="indicator">
        <img src="/domain_logo.png" alt="logo" className="w-10 h-10 self-center ml-4"></img>
        <span className="indicator-item badge badge-warning text-[0.8em] mt-1 shadow-xl">BETA</span> 
        <Link className="btn btn-ghost normal-case text-xl" to="/">RedirectTo.Me</Link>
      </div>
    </div>
    <div className="flex-none">
      <ul className="menu menu-horizontal px-1">
        <li><Link className={props.location && props.location.href && props.location.href.includes("/faq") ? "font-bold" : ""} to="/faq">FAQ & How-To Guides</Link></li>
        <li><Link className={props.location && props.location.href && props.location.href.includes("/profile") ? "font-bold" : ""} to="/app/profile">Profile</Link></li>
        <li><Link className={props.location && props.location.href && props.location.href.includes("/domains") ? "font-bold" : ""} to="/app/domains">Domains</Link></li>
      </ul>
    </div>
  </div>
  </>
)

export default Header
